import {Badge, Button, Modal} from "react-bootstrap";
import './index.css';
import "./react-datepicker.css";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-clock/dist/Clock.css';

import Table from "react-bootstrap/Table";
import {useState} from "react";
import {makeRequest} from "../../lib/api/request";
import {formattedDate} from "../../lib/utils/fixdate";
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker'
import { DateTime } from 'luxon';

const processPayment = (payment) => {
    payment.actualAmount = parseFloat(payment.actualAmount);
    payment.amount = parseFloat(payment.amount);
    payment.editedAmount = payment.actualAmount;
    return payment
}

// display with 2 numbers after the decimal point
const displayMoney = (amount) => amount.toFixed(2);

const EditPaymentsModal = (props) => {
    const utcToday = new Date(DateTime.now().toUTC());
    const {promise, show, handleClose, callback} = props;

    const [payments, setPayments] = useState(promise.payments);
    const [loaded, setLoaded] = useState(true);

    const [remainder, setRemainder] = useState(0);
    const [editedDates, setEditedDates] = useState([]);
    const [editedHours, setEditedHours] = useState([]);

    const setValue = (id, value, changeType) => {
        // TODO: validate value ???
        let localPayments = payments;
        localPayments.map(p => {
            if (p.id === id) {
                if (changeType === "amount") {
                    if (typeof(p.amount) === "string") {
                        p = processPayment(p)
                    }
                    let difference = p.editedAmount - value;
                    p.editedAmount = value;
                    setRemainder(roundTo(remainder + difference, 2));
                } else if (changeType === "date") {
                    console.log('trying to change date:', p, value)
                    p.scheduledPaymentDate = value;
                    if (!editedDates.includes(p.id)) setEditedDates([p.id, ...editedDates]);
                } else if (changeType === "time") {
                  console.log('trying to change time:', p, value)
                  p.paymentDate = value;
                  if (!editedHours.includes(p.id)) setEditedHours([p.id, ...editedHours]);
                }
            }
        })
        setPayments([...localPayments])
    }

    const roundTo = (n, digits) => {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        let multiplier = Math.pow(10, digits);
        n = parseFloat((n * multiplier).toFixed(11));
        n = (Math.round(n) / multiplier).toFixed(digits);
        if (negative) {
            n = (n * -1).toFixed(digits);
        }
        return parseFloat(n);
    }
    const saveChanges = () => {
        setLoaded(false)
        let toUpdate = payments.filter(p => p.editedAmount !== undefined || editedDates.includes(p.id))
        if (payments.filter(p => p.editedAmount < 0).length > 0) {
            return
        }
        toUpdate.map((p, index) => {
            makeRequest(`promises/payments/${p.id}/`, 'PATCH', {
                "edited_amount": roundTo(p.editedAmount, 2),
                "scheduled_payment_date": p.scheduledPaymentDate
            }).then(response => {
                if (index === toUpdate.length - 1) {
                    setLoaded(true);
                    handleClose();
                    callback();
                }
            })
        })

        let timeUpdate = payments.filter(p => editedHours.includes(p.id))
        timeUpdate.map((p, index) => {
          makeRequest(`promises/payments/${p.id}/`, 'PATCH', {
            "paymentDate": p.paymentDate
        }).then(response => {
            if (index === timeUpdate.length - 1) {
                setLoaded(true);
                handleClose();
                callback();
            }
        })
        })
    }

    const getStatusDisplay = (status) => {
        let badgeText = '';
        switch (status) {
            case "in_progress":
                badgeText = 'ESTABLISHED';
                break;
            case "scheduled":
                badgeText = 'SCHEDULED';
                break;
            case "missed":
            case "failed":
                badgeText = 'FAILED';
                break;
            case "cancelled":
                badgeText = 'CANCELLED';
                break;
            case "completed":
                badgeText = 'PAID OFF';
                break;
            default:
                badgeText = "UNKNOWN";
                break;
        }
        return <Badge bg='secondary'>{badgeText}</Badge>
    }

    const handleDateChange = (id, d) => {
        const resultingString = DateTime.fromJSDate(d).toFormat('yyyy-MM-dd')
        setValue(id, resultingString, "date")
    }

    const changeTimePicker = (id, d) => {
      setValue(id, d.toISOString(), "time");
    }

    return <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <h3 className={'my-2'}>Edit Schedule</h3>
            <div className={'d-flex flex-row'}>
                <div className={'mr-2'}>
                    <span className={'body-bold edit-form-title'}>Total </span>
                    <span className={'body-reg'}>${promise.remainder}</span>
                </div>
                <div>
                    <span className={'body-bold edit-form-title'}>Remaining </span>
                    <span className={'body-reg'}>${remainder}</span>
                </div>
            </div>
            <div className={'scrollable-table'}>
                <Table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map((payment, index) => {
                        let fieldId = `payment-input-${payment.id}`;
                        let dateForPicker;
                        if (payment.scheduledPaymentDate) {
                            // console.log('SCHED DATE', payment.scheduledPaymentDate)
                            dateForPicker = new Date(DateTime.fromISO(payment.scheduledPaymentDate));
                        }
                        return <tr key={index}>
                            <td>
                                {payment.status === "scheduled" && (
                                  <DatePicker
                                    key={`datepicker-${payment.id}`}
                                    value={dateForPicker}
                                    minDate={utcToday}
                                    onChange={(date) => handleDateChange(payment.id, date)}
                                    format={"y-MM-dd"}
                                    clearIcon={null}
                                  />
                                )}
                                {payment.status !== "scheduled" && payment.paymentType !== "Check" && (
                                  formattedDate(payment.paymentDate ? payment.paymentDate : payment.scheduledPaymentDate)
                                )}
                                {payment.status !== "scheduled" && payment.paymentType === "Check" && (
                                  <DateTimePicker
                                    onChange={(date) => changeTimePicker(payment.id, date)}
                                    value={payment.paymentDate}
                                    format={"y-MM-dd HH:mm"}
                                    disableClock={true}
                                    disableTimePicker={true}
                                    clearIcon={null}
                                  />
                                    )}
                            </td>
                            <td>
                                <input
                                    className={'small-input'}
                                    type={'number'}
                                    value={payment.editedAmount ? payment.editedAmount : payment.actualAmount}
                                    min={0}
                                    disabled={payment.status !== 'scheduled'}
                                    onChange={(e) => setValue(payment.id, e.target.value, "amount")}
                                    id={fieldId}
                                />
                                {payment.editedAmount && payment.editedAmount < 0 && <div style={{color: "#dc3545", fontSize: "0.85rem"}}>Value can't be less than 0</div>}
                                {payment.editedAmount && payment.editedAmount === 0 && <div style={{color: "var(--solid)", fontSize: "0.85rem"}}>Payment will be closed.</div>}
                            </td>
                            <td>{getStatusDisplay(payment.status)}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
                Close
            </Button>
            <Button variant="primary" onClick={() => saveChanges()} disabled={remainder !== 0 || promise.status === "deleted"}>
                Save Changes
            </Button>
        </Modal.Footer>
    </Modal>
}

export default EditPaymentsModal;