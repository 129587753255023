import React, { createContext, useContext, useEffect, useState } from 'react';

const INVESTINET = 'investinet';
const CONSUEGRA = 'consuegra';
const DEMO = 'demo';
const TROMBERG = "tromberg";
const TIDEWATER = "tidewater";
const NCLS = "northcentrallegalsolutions";

export const TENANTS = {
    investinet: INVESTINET,
    consuegra: CONSUEGRA,
    demo: DEMO,
    tromberg: TROMBERG,
    tidewater: TIDEWATER,
    ncls: NCLS,
};

const hostnameTenantMapping = {
    'consuegralaw': TENANTS.consuegra,
    'demo': TENANTS.demo,
    'tmppllc': TENANTS.tromberg,
    'twcs': TENANTS.tidewater,
    'northcentrallegalsolutions': TENANTS.ncls,
    'investinet': TENANTS.investinet,
};

const defaultContext = {
    tenant: TENANTS.investinet,
    setTenant: () => {}
};

export const TenantContext = createContext(defaultContext);

export const useTenantContext = () => useContext(TenantContext);

const determineTenant = (hostname) => {
    const tenantRegex = /^(?:https?:\/\/)?(?:www\.)?[a-z0-9-]+\.(?<tenant>[a-z0-9-]+)\./i;
    const match = hostname.match(tenantRegex);
    const tenantName = match?.groups?.tenant;

    if (tenantName && hostnameTenantMapping[tenantName]) {
        return hostnameTenantMapping[tenantName];
    }
    return TENANTS.investinet;
};

export const TenantContextProvider = ({ children }) => {
    const [tenant, setTenant] = useState(determineTenant(window.location.hostname));

    useEffect(() => {
        const currentTenant = determineTenant(window.location.hostname);
        if (currentTenant !== tenant) {
            setTenant(currentTenant);
        }
    }, [tenant]);

    return (
        <TenantContext.Provider value={{ tenant, setTenant }}>
            {children}
        </TenantContext.Provider>
    );
};
